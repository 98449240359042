/*
 |--------------------------------------------------------------------------
 | Bootstrap The Application
 |--------------------------------------------------------------------------
 |
 | First we will load all of this project's JavaScript dependencies which
 | includes Vue and other libraries. It is a great starting point when
 | building robust, powerful web applications using Vue and Laravel.
 |
 */

require('./bootstrap');

/*
 |--------------------------------------------------------------------------
 | Custom Elements
 |--------------------------------------------------------------------------
 |
 | Here we'll set up the custom components, plugins, and section specific
 | javascript files that are going to be needed to run our application
 | this is a nice place to require all sorts of custom jQuery code.
 |
 */

require('./plugins/eonasdan-bootstrap-datetimepicker/bootstrap-datetimepicker');

require('./plugins/holdon');
require('./html/carousel');
require('./html/modals');
require('./html/search');
require('./html/payment');
require('./html/paymentMembership');
require('./html/paymentServices');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

new window.Vue({
    components: {
        PasswordToggle: require('./components/PasswordToggle').default,
    },
}).$mount('#app');
