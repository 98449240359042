/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */

$(function () {

    'use strict';

    /**
     * Search trigger.
     */
    $('.navbar-search').click((e) => {
        e.preventDefault();

        $('.search-container').toggleClass('show').find('input[type="search"]').attr('autofocus', true);
        $('.navbar-toggler').addClass('navbar-toggler collapsed').attr('aria-expanded', false);
        $('.navbar-collapse').removeClass('show');

    });

    $(document).keyup((e) => {
        if (e.key === 'Escape' && $('.search-container').hasClass('show')) {
            $('.search-container').removeClass('show');
        }
    });
});
