require('../plugins/holdon');
require('moment');

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(document).ready(function() {

    'use strict';

    if ($('#card_number').length) {
        $('#card_number').on('keyup', guessPaymentMethod);
        $('#card_number').on('change', guessPaymentMethod);
    }

    if ($('#select-card').length) {
        $('#select-card').change(function() {
            var card = $(this).find(':selected').data('card');
            $('#card_number').val(card.number);
            $('#card_name').val(card.name);
            $('#card_month').val(card.month);
            $('#card_year').val(card.year);
            $('#card_type').val(card.type);
            guessPaymentMethod();
        });
    }

    function guessPaymentMethod(event) {
        let cardnumber = $('#card_number').val();

        if (cardnumber.length >= 6) {
            let bin = cardnumber.substring(0,6);
            window.Mercadopago.getPaymentMethod({
                "bin": bin
            }, setPaymentMethod);
        }
    };

    function setPaymentMethod(status, response) {
        if (status == 200) {
            let paymentMethodId = response[0].id;
            let element = $('#payment_method_id');
            element.val(paymentMethodId);
            //getInstallments();
        } else {
            alert(`payment method info error: ${response}`);
        }
    }

    function getInstallments(){
        window.Mercadopago.getInstallments({
            "payment_method_id": $('#payment_method_id').val(),
            "amount": parseFloat($('#transaction_amount').val())

        }, function (status, response) {
            if (status == 200) {
                document.getElementById('installments').options.length = 0;
                response[0].payer_costs.forEach( installment => {
                    let opt = document.createElement('option');
                    opt.text = installment.recommended_message;
                    opt.value = installment.installments;
                    document.getElementById('installments').appendChild(opt);
                });
            } else {
                alert(`installments method info error: ${response}`);
            }
        });
    }

    $('#btn-pay-card').click(function() {
        doPay();
    });

    function doPay(){
        
        HoldOn.open({ message: 'Procesando datos, por favor espere.' });

        var form = {
            'description': $('#membership_name').val(),
            'transaction_amount': $('#transaction_amount').val(),
            'cardNumber': $('#card_number').val(),
            'cardholderName': $('#card_name').val(),
            'cardExpirationMonth': $('#card_month').val(),
            'cardExpirationYear': $('#card_year').val(),
            'securityCode': $('#card_cvc').val(),
            'email': $('#user_email').val(),
            'installments': $('#installments').val(),
            'payment_method_id': $('#payment_method_id').val(),
        }

        window.Mercadopago.createToken(form, sdkResponseHandler);
    };

    function sdkResponseHandler(status, response) {
        if (status != 200 && status != 201) {
            HoldOn.close();
            alert("verify filled data");
        }else{
            var form = {
                'membership_id': $('#membership_id').val(),
                'installments': $('#installments').val(),
                'payment_method_id': $('#payment_method_id').val(),
                'card_token': response.id,
                'card': {
                    'id': $('#select-card').val(),
                    'name': $('#card_name').val(),
                    'number': $('#card_number').val(),
                    'month': $('#card_month').val(),
                    'year': $('#card_year').val(),
                    'type': $('#card_type').val(),
                },
            };

            axios.post('/membership/payment/card', form)
                .then(response => {
                    HoldOn.close();
                    if (response.data.order.status === 'approved') {
                        alert('Tu pago fue aceptado, revisa tu cuenta para ver tu nueva membresia.');

                        $('#div_payment').addClass('d-none');
                        $('#div_paid').removeClass('d-none');
                    }
                })
                .catch(error => {
                    HoldOn.close();
                    console.log(error);
                });
        }
    };

    $('#btn-pay-oxxo').click(function(){
        HoldOn.open({ message: 'Procesando datos, por favor espere.' });
        
        var form = {
                'membership_id': $('#membership_id').val(),
                'email': $('#email_oxxo').val(),
            };

        axios.post('/membership/payment/oxxo', form)
            .then(response => {
                HoldOn.close();
                console.log(response.data.order);
                if (response.data.order.status === 'pending') {
                    alert('Reviza tu email para ver instrucciones de pago.');

                    $('#div_payment').addClass('d-none');
                    $('#div_paid').removeClass('d-none');
                }
            })
            .catch(error => {
                HoldOn.close();
                console.log(error);
            });
    });

});
