/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */

$(function () {

    'use strict';

    /**
     * Modals trigger.
     */
    /*if ($('#serviceVideoModal').length > 0) {
        $('#serviceVideoModal').modal();
    }

    if ($('#membershipModal').length > 0) {
        $('#membershipModal').modal();
    }

    if ($('#serviceModal').length > 0) {
        $('#serviceModal').modal();
    }

    if ($('#serviceModal').length > 0) {
        $('#serviceModal').modal();
    }
    */
    if ($('#registerModal').length > 0) {
        $('#registerModal').modal();
    }
});
