//require('../plugins/holdon');

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(document).ready(function() {

    'use strict';

    $('#horary_id').change(function() {
        $('#btn-netx-membership').prop("disabled", false);
        $('#btn-next-payment').removeClass('disabled');
    });

    $('#btn-service-card').click(function() {
        doPay();
    });

    function doPay(){
    	HoldOn.open({ message: 'Procesando datos, por favor espere.' });

        var form = {
            'description': $('#service_name').val(),
            'transaction_amount': $('#transaction_amount').val(),
            'cardNumber': $('#card_number').val(),
            'cardholderName': $('#card_name').val(),
            'cardExpirationMonth': $('#card_month').val(),
            'cardExpirationYear': $('#card_year').val(),
            'securityCode': $('#card_cvc').val(),
            'email': $('#user_email').val(),
            'installments': $('#installments').val(),
            'payment_method_id': $('#payment_method_id').val(),
        }

        window.Mercadopago.createToken(form, sdkResponseHandler);
    };

    function sdkResponseHandler(status, response) {
        if (status != 200 && status != 201) {
        	HoldOn.close();
            alert("verify filled data");
        }else{
            var form = {
                'service_id': $('#service_id').val(),
                'installments': $('#installments').val(),
                'payment_method_id': $('#payment_method_id').val(),
                'card_token': response.id,
                'name': $('#customer_name').val(),
                'phone': $('#customer_phone').val(),
                'email': $('#customer_email').val(),
                'gender': $('#customer_gender').val(),
                'age': $('#customer_age').val(),
                'description' : $('#customer_comments').val(),
                'date': $('#date_service').val(),
                'horary_id': $('#horary_id').val(),
                'code': $('#membership_code').val(),
                'card': {
                    'id': $('#select-card').val(),
                    'name': $('#card_name').val(),
                    'number': $('#card_number').val(),
                    'month': $('#card_month').val(),
                    'year': $('#card_year').val(),
                    'type': $('#card_type').val(),
                },
            };

            axios.post('/services/payment/card', form)
                .then(response => {
                	HoldOn.close();
                    if (response.data.order.status === 'approved') {
                        alert('Tu pago fue aceptado, revisa tu cuenta para ver tu nueva membresia.');
                        
                        location.reload();
                    }
                })
                .catch(error => {
                	HoldOn.close();
                    console.log(error);
                });
        }
    };

    if ($('#membership_code').length) {
	    document.getElementById('membership_code').addEventListener('keyup', checkCode);
	    document.getElementById('membership_code').addEventListener('change', checkCode);
	}

    function checkCode(event) {
        let cardnumber = document.getElementById("membership_code").value;
        $('#membership_code_message').text('');
        $('#membership_code_next').prop("disabled", true);

        if (cardnumber.length) {
            axios.get('/membership/'+cardnumber)
                .then(response => {
                    if (response.data.membership) {

                        if (response.data.membership.is_paid) {
                            $('#membership_code_next').prop("disabled", false);

                            // Aplicar descuento
                            var amount = $('#transaction_amount').val();
                            var porcent = response.data.membership.discount / 100;
                            var discount = amount * porcent;
                            var total = amount - discount;

                            $('#service_discount').text(discount);
                            $('#service_total').text(total);
                        } else {
                            $('#membership_code_message').text('Código inválido.');
                        }
                    } else {
                        $('#membership_code_message').text('Código inválido.');
                    }
                    
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    $('#btn-service-oxxo').click(function(){
        HoldOn.open({ message: 'Procesando datos, por favor espere.' });

        var form = {
                'service_id': $('#service_id').val(),
                'name': $('#customer_name').val(),
                'phone': $('#customer_phone').val(),
                'email': $('#customer_email').val(),
                'email_oxxo': $('#email_oxxo').val(),
                'gender': $('#customer_gender').val(),
                'age': $('#customer_age').val(),
                'date': $('#date_service').val(),
                'horary_id': $('#horary_id').val(),
                'code': $('#membership_code').val(),
            };

        axios.post('/services/payment/oxxo', form)
            .then(response => {
                HoldOn.close();
                if (response.data.order.status === 'pending') {
                    alert('Tu pago fue aceptado, revisa tu cuenta para ver tu nueva membresia.');
                    
                    location.reload();
                }
            })
            .catch(error => {
                HoldOn.close();
                console.log(error);
            });
    });

    $('.next-step').click(function (event) {
        event.preventDefault();
        let $this = $(this),
            step = $this.data('step');

        if (step == 'div_payment') {
            if ($('#membership_code_next').prop("disabled")) {
                $('#membership_discount').addClass('d-none');
            } else {
                $('#membership_discount').removeClass('d-none');
            }
        }
    });

});
