//require('../plugins/holdon');
require('moment');

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(document).ready(function() {

    'use strict';

    $('.next-step').click(function (event) {
        event.preventDefault();
        let $this = $(this),
            step = $this.data('step');

        $('.tab-service').removeClass('active');

        $('#'+step).tab('show');
    });

});
